<template>
  <div>
    <multiselect
      ref="multiselect"
      v-model="selectedTags"
      deselect-label=""
      class="customUI col-span-4 ag-input"
      style=" max-width: 635px; overflow-x: scroll; overflow-y: hidden;"
      :multiple="true"
      :hide-selected="true"
      :max-height="150"
      :taggable="true"
      placeholder=""
      open-direction="above"
      tag-position="bottom"
      :options-limit="4"
      :options="availableTags"
      :searchable="true"
      :allow-empty="true"
      @open="updateDropdownSize"
      @select="selectTag"
      @remove="removeTag"
      @tag="addTag"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      selectedTags: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableTags: [],
      highlightAllOnFocus: true,
      value: [],
    };
  },
  async beforeMount() {
   
    const objectID = this.params.data.Well;

    const wellTags = await scheduleModule.getTagsForWell({ node_id: objectID });
    if (wellTags.length) {
     this.selectedTags = this.selectedTags.concat(wellTags.map((t) => JSON.parse(t).toLowerCase()));
    }
  },

  async created() {
    const groups = (scheduleModule.activeTableGroups);
    let payload = [];
    let tags = [];
    const allWellTags = scheduleModule.availableTags;
    if (allWellTags.length) {
      tags = (allWellTags);
      tags = tags.map(t => t.toLowerCase())
    }

   this.availableTags = [...new Set(tags)].sort();
  },

  isPopup() {
    return true;
  },

  methods: {

    activeTableGroups() {
      return scheduleModule.activeTableGroups;
    },

    updateDropdownSize() {
      const ms = this.$refs.multiselect;
      ms.$refs.list.style.width = `${this.$el.clientWidth}px`;
      ms.$refs.list.style.position = 'fixed';
    },
     sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
    async removeTag(removeT) {
      await this.sleep(200)
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      let tags = this.selectedTags
      const index = tags.indexOf(removeT);
      if (index > -1 && tags.includes(removeT)) {
        tags.splice(index, 1);
      }
       this.selectedTags = this.selectedTags.map(t => t.toLowerCase())
      // console.log(this.selectedTags);
      await this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, this.selectedTags);
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: tags });
    },
    async addTag(newTag) {
       await this.sleep(200)
      this.selectedTags.push(newTag)
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      this.value = this.selectedTags;
      this.selectedTags = this.selectedTags.map(t => t.toLowerCase())
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
        await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectedTags });
      this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, (this.selectedTags));
    },

    async selectTag() {
 await this.sleep(200)
     
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      this.value = this.selectedTags;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
       this.selectedTags = this.selectedTags.map(t => t.toLowerCase())
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectedTags });
      this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, (this.selectedTags));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: #283751;
    border-color: transparent;
    padding: 4px;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> input {
  background: #283751;
  color: white;
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
  display: none;
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;

    position: fixed;
    z-index: 99999 !important;
    height: 150px !important;
  top: 50px;
  width: 300px;

}

.customUI >>> .multiselect--active {
  z-index: 10;
}
</style>
